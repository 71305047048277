<!-- Loader -->
<div id="loader" *ngIf="loading" [@fade]>
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<!-- Fin Loader -->
<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="content">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="page-title-box">
              <!-- <a class="btn btn-sm btn-primary float-left me-2 mt-2" routerLink="/">
                <i class="dripicons-home"></i> Dashboard
              </a> -->
              <a class="btn btn-sm btn-info float-left me-2 mt-2" href="https://equestrian.digital/show/us_{{idConcurso}}" target="_blank">
                <i class="dripicons-trophy"></i> Results
              </a>
              <!-- <a class="btn btn-sm btn-success float-left me-2 mt-2" [routerLink]="['/add-drops']" routerLinkActive="router-link-active" >
                <i class="dripicons-pencil"></i> Add/Drop
              </a> -->
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex mt-2">
              <h5>Filter by day: &nbsp;</h5>
              <div class="btn-group mb-2">
                <button type="button" class="btn btn-primary" [ngClass]="{'active': !filtro}" (click)="filtrarAlumnosPorDia('')">All</button>
                <ng-container *ngFor="let dia of dias">
                  <button *ngIf="dia.date != ''" type="button" class="btn btn-primary" [ngClass]="{'active': dia.date == filtro}" (click)="filtrarAlumnosPorDia(dia.date)">{{ dia.date }}</button>
              </ng-container>
              </div>
            </div>
          </div>
          <div class="col-12">
            <ng-container *ngFor="let dia of dias">
              <table class="table" *ngIf="(filtro && filtro == dia.date) || !filtro">
                <thead class="table-dark">
                  <tr>
                    <th colspan="3"> {{ dia.date }} </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let ring of dia.rings">
                    <tr class="table-primary">
                      <td colspan="3">
                        <b> Ring: {{ ring.name }} </b>
                      </td>
                    </tr>
                    <ng-container *ngFor="let class of ring.classes">
                        <tr class="table-info">
                          <td>
                            <b> Class: {{ class.number }} - {{ class.entries[0].fullname}}</b>
                          </td>
                          <td colspan="2" class="text-end">
                            <b> Entries: {{ class.entries.length }} </b>
                          </td>
                        </tr>
                        <ng-container *ngFor="let alumno of class.entries">
                          <tr>
                            <td>
                              <span *ngIf="alumno.publicado">Order: {{ alumno.entrada }}</span>
                            </td>
                            <td>
                              {{ alumno.jinete }} - {{alumno.cucarda}} - {{ alumno.caballo }}
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
