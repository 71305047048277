import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { ShowSelectedGuard } from './select-show/show-selected.guard';

import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { CreateEntryComponent } from './create-entry/create-entry.component';
import { SelectShowComponent } from './select-show/select-show.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { BetterpayCheckoutComponent } from './betterpay-checkout/betterpay-checkout.component';
import { MyEntriesComponent } from './my-entries/my-entries.component';
import { OnlineCheckoutComponent } from './online-checkout/online-checkout.component';
import { EntryInvoiceComponent } from './entry-invoice/entry-invoice.component';
import { AddDropsComponent } from './add-drops/add-drops.component';
import { TrainerReservationComponent } from './trainer-reservation/trainer-reservation.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { HistoryComponent } from './history/history.component';
import { EntryComponent } from './entry/entry.component';
import { AddClassesComponent } from './add-classes/add-classes.component';
import { AddEntryComponent } from './add-entry/add-entry.component';
import { CheckoutReiningComponent } from './checkout-reining/checkout-reining.component';
import { EditEntryReinignComponent } from './edit-entry-reinign/edit-entry-reinign.component';
import { EditEntryComponent } from './edit-entry/edit-entry.component';
import { MyCatalogComponent } from './my-catalog/my-catalog.component';
import { StallsComponent } from './stalls/stalls.component';
import { FeedOrdersComponent } from './feed-orders/feed-orders.component';

const redirectToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    component: MyEntriesComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'online-checkout/:idConcurso/:email',
    component: OnlineCheckoutComponent,
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    pathMatch: 'full'
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    pathMatch: 'full'
  },
  {
    path: 'create-entry',
    component: CreateEntryComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'create-entry/:id',
    component: CreateEntryComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'add-entry',
    component: AddEntryComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'select-show',
    component: SelectShowComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard,ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'betterpay-checkout',
    component: BetterpayCheckoutComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard,ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'checkout-reining',
    component: CheckoutReiningComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'entry-invoice/:idConcurso/:entry',
    component: EntryInvoiceComponent,
    pathMatch: 'full',
  },
  {
    path: 'entry/:idBinomio',
    component: EntryComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'add-drops',
    component: AddDropsComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'add-classes/:idBinomio',
    component: AddClassesComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'trainer-reservation',
    component: TrainerReservationComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'trainer-list',
    component: ScheduleComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'history',
    component: HistoryComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'edit-entry/:id',
    component: EditEntryComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'edit-entry-reining/:id',
    component: EditEntryReinignComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'catalog',
    component: MyCatalogComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'stalls',
    component: StallsComponent,
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: 'feed-orders',
    component: FeedOrdersComponent,
    canActivate: [ AuthGuard, ShowSelectedGuard ],
    data: { authGuardPipe: redirectToLogin }
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
