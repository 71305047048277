import { Component, OnInit } from '@angular/core';
import { EntriesService } from '../services/entries.service';
import { PersonsService } from '../services/persons.service';
import { AuthService } from './../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { errorNotification } from '../services/notifications.service';
import { ShowsService } from '../services/shows.service';
import { firstValueFrom } from 'rxjs';
declare var bootstrap: any;

@Component({
  selector: 'app-my-entries',
  templateUrl: './my-entries.component.html',
  styleUrls: ['./my-entries.component.scss']
})
export class MyEntriesComponent implements OnInit {
  public drafts: any[];
  public oldEntries: any[];
  public showID: string;
  public showName: string;
  public email: string;
  public reining: boolean;
  public checkoutRoute: string;
  public selectedEntry: any;
  public show: any;
  public classDetails: any[] = [];

  constructor(
    private _personsService: PersonsService,
    private _entriesService: EntriesService,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private _showsService: ShowsService
  ) {
    this.drafts = [];
    this.oldEntries = [];
    this.selectedEntry = null;
    const routeParams = this.route.snapshot.paramMap;
    this.reining = sessionStorage.getItem('reining') === '1';
    this.checkoutRoute = this.reining ? '/checkout-reining' : '/checkout';
    this.email = localStorage.getItem('email') || (sessionStorage.getItem('email') || '');
    this.showID = sessionStorage.getItem('showID')||'';
    this.showName = sessionStorage.getItem('showname')||'';
  }

  async ngOnInit() {
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    
    // First get office entries, then get online entries
    await this.getOfficeEntries();
    await this.getEntries();
    await this.getShow();
  }

  public async getEntries() {
    return new Promise<void>((resolve, reject) => {
      this._personsService.getDocumentByEmail(this.email)
        .then((response: any) => {
          this._entriesService.getEntries(response.uid, this.showID)
            .then((d: any) => {
              this.drafts = d;

                // Remove duplicates from drafts
                console.log("Drafts: ", this.drafts[0]);
                console.log("Office Entries: ", this.oldEntries[0]);

                // Filter out drafts that have matching horse names in oldEntries
                if (this.oldEntries && this.oldEntries.length > 0) {
                  const existingHorseNames = new Set(
                    this.oldEntries.map(entry => entry.caballo?.horseName?.toLowerCase())
                  );
                  this.drafts = this.drafts.filter(draft => {
                    const draftHorseName = draft.horseName?.toLowerCase();
                    return !draftHorseName || !existingHorseNames.has(draftHorseName);
                  });
                }
                
                
                 
              console.log(d);
              resolve();
            })
            .catch((error: Error) => {
              console.warn(error);
              reject(error);
            });
        })
        .catch((error: Error) => {
          console.warn(error);
          reject(error);
        });
    });
  }

  async getOfficeEntries() {
    return new Promise<void>((resolve, reject) => {
      const currentShowID: any = 1;

      this._entriesService.getSolitudesPorMail(this.email, currentShowID)
        .then((response: any) => {
          if(response.error) {
            errorNotification('Error', response.message);
            reject(response.error);
            return;
          }
          this.oldEntries = response.solicitudes||[];
          // Filter out the old entries that don't have this showID
          this.oldEntries = this.oldEntries.filter((entry: any) => entry.id_concurso == this.showID);
          console.log('Office Entries: ', this.oldEntries);
          resolve();
        })
        .catch((error: Error) => {
          console.warn(error);
          reject(error);
        });
    });
  }

  async showEntryDetails(entry: any) {
    this.selectedEntry = entry;
    if (entry.pruebas && entry.pruebas.length > 0) {
      try {
        // Get all classes from the show
        const showResponse = await firstValueFrom(this._showsService.getClassesByShow(this.showID));
        const allClasses = showResponse.data;
        
        console.log('Selected Entry Pruebas:', entry.pruebas);
        console.log('All Classes:', allClasses);
        
        // Map the class IDs to their full details
        this.classDetails = entry.pruebas.map((prueba: any) => {
          const foundClass = allClasses.find((c: any) => c.id_prueba_concurso == prueba);
          console.log('Looking for ID:', prueba, 'Found:', foundClass);
          return foundClass;
        }).filter(Boolean); // Remove any undefined results
        
        console.log('Final Class Details:', this.classDetails);
      } catch (error) {
        console.error('Error fetching class details:', error);
      }
    }
    // Show modal
    const modal = document.getElementById('entryDetailsModal');
    if (modal) {
      const bsModal = new bootstrap.Modal(modal);
      bsModal.show();
    }
  }

  public getShow() {
    this._showsService.getShowNest(this.showID).subscribe((response) => {
      this.show = response.data.concurso;
    });
  }
}
